import React from 'react';
import { Paper, LinearProgress} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import UsersTable from './UsersTable';
import EditAccountDialog from './EditAccountDialog';
import CustomToolbar from './CustomTableToolBar';
import CreateIcon from "@material-ui/icons/Create";

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    createIcon: {
        cursor: 'pointer'
    }
});

class AccountsTable extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            accounts: undefined,
            clickedIndex: undefined,
            editOpen: false
        }
        this.getAccounts = this.getAccounts.bind(this);
        this.createNewAccountRow = this.createNewAccountRow.bind(this);
        this.onRowsDelete = this.onRowsDelete.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.doDelete = this.doDelete.bind(this);
        this.onEditAccountClose = this.onEditAccountClose.bind(this);
    }
    headRows = [
        { id: 'id', numeric: false,  label: 'Id' },
        { id: 'name', numeric: false,  label: 'Name' },
        { id: 'owner', numeric: false,  label: 'Owner' },
        { id: 'status', numeric: false, label: 'Status' },
        { id: 'created_at', numeric: false, label: 'Created At' },
        { id: 'actions', label: 'Actions', options: {
            filter: false, 
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <CreateIcon className={this.props.classes.createIcon} onClick={(e) => {
                        this.onEditClick(tableMeta.rowIndex);
                        e.stopPropagation();
                        return true;                
                    }} />
                );
            },
        }}
    ];
    async onEditClick(idx) {
        await this.setState({editOpen: true, clickedIndex: idx });
    }

    async getAccounts() {
        await this.setState({ isLoading: true });
        const data = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/account`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: "GET",
            credentials: 'include'
        });
        const json = await data.json();
        if (data.status === 201 || data.status === 200) {
            await this.setState({accounts: json.accounts});
        } else {
            console.log(`issues when trying to fetch accounts: ${data.statusText}`);
        }
        await this.setState({ isLoading: false });
    }

    async componentDidMount() {
        await this.getAccounts();
    }
    
    async createNewAccountRow() {
        const {accounts} = this.state;
        const newRow = { id :undefined, name: 'Account Name', owner: undefined, status: 0, created: undefined };
        accounts.splice(0, 0, newRow); // or push(newRow) to put at the end
        await this.setState({accounts})
    }
    // async onRowClick(rowData, rowMeta) {
        // await this.setState({editOpen: true, clickedIndex: rowMeta.rowIndex });
    // }

    async onEditAccountClose() {
        this.setState({editOpen: false, clickedIndex: undefined});
    }

    async doDelete(accountId) {
        await this.setState({ isLoading: true });
        const data = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/account/${accountId}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: "DELETE",
            credentials: 'include'
        });
        const json = await data.json();
        if (data.status === 201 || data.status === 200) {
            const {accounts} = this.state;
            const newArray = [];
            const updated = accounts.map(a => {
                if (a.id !== accountId) newArray.push(a);
            });
            await this.setState({accounts: newArray});
        } else {
            console.log(`issues when trying to fetch accounts: ${data.statusText}`);
        }
        await this.setState({ isLoading: false });

    }
    async onRowsDelete(rowsDeleted, data) {
        // console.log(rowsDeleted); //object of index=>boolean (selected for delish)
        if (rowsDeleted.data.length > 1) {
            console.log('Deleting more than one account is not supported atm.');
            return false;
        } else {
            const accountToDelete = this.state.accounts[rowsDeleted.data[0].dataIndex];
            await this.doDelete(accountToDelete.id);
        }
    }
    render() {
        const {classes} = this.props;
        const { isLoading, accounts, clickedIndex, editOpen } = this.state;        
        return <div>
            <Paper className={classes.root}>
                <MUIDataTable 
                    title={"Accounts"}
                    data={accounts && accounts.map(t => {
                        return [t.id, t.name, t.owner, t.status, t.created];
                    })  || undefined}
                    columns={this.headRows.map(h=> {
                        return {
                            name: h.id,
                            label: h.label,
                            options: h.options
                        };
                    })}
                    options={{
                        expandableRows: true, 
                        expandableRowsOnClick: true, 
                        renderExpandableRow: (rowData, rowMeta) => { 
                            return <UsersTable account_id={rowData[0]} />
                        },
                        onRowsDelete: this.onRowsDelete, 
                        responsive: 'scroll',
                        customToolbar: () => {
                            return (
                                <CustomToolbar onCreate={this.createNewAccountRow} />
                            );
                        },
                        filterType: 'dropdown', pagination: false, sort: true, viewColumns: false
                    }}
                />
                { isLoading && <LinearProgress /> }
                {clickedIndex !== undefined && <EditAccountDialog account={accounts[clickedIndex]} open={editOpen} onClose={this.onEditAccountClose} />}
            </Paper>
      </div>
    }
}

AccountsTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AccountsTable);