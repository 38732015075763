import React from 'react';
import PropTypes from 'prop-types';
import {DialogTitle, Dialog, TextField, DialogContent, LinearProgress, DialogActions, Button} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        minWidth: '360px',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    }
});


class EditAccountDialog extends React.Component {    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            account: this.props.account,
        }
        this.onChange = this.onChange.bind(this);
        this.doUpdate = this.doUpdate.bind(this);
    }
    onChange(e) {
        const {account} = this.state;
        account[[e.target.name]] = e.target.value;
        this.setState(account);
    }

    async doUpdate() {
        const {account} = this.state;
        const method = account.id ? 'PUT' : 'POST';
        const url = account.id ? `https://stage.api.simpool.io/api/auth/account/${account.id}` : 
            `https://stage.api.simpool.io/api/auth/account`;
        await this.setState({ isLoading: true });
        const data = await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method,
            credentials: 'include',
            body: JSON.stringify(Object.assign({}, this.state.account, {email: 'email.not@set.com'}))
        });
        const json = await data.json();
        if (data.status === 201 || data.status === 200) {
            console.log('updated the account', JSON.stringify(json));
            this.props.onClose();
        } else {
            console.log(`issues when trying create/update the account: ${data.statusText}`);
        }
        await this.setState({ isLoading: false });        
    }
    
    render() {
        const {classes, open, onClose} = this.props;
        const {account, isLoading} = this.state;
        return (
            <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} className={classes.root}>
                <DialogTitle id="simple-dialog-title">Edit Account</DialogTitle>
                <DialogContent dividers>
                    <TextField label='Name' name='name' onChange={this.onChange} value={account.name} />
                    {isLoading && <LinearProgress />}                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary">
                        CANCEL
                    </Button>
                    <Button onClick={this.doUpdate} color="primary" autoFocus>
                        {account.id && 'UPDATE' || 'CREATE'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditAccountDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    account: PropTypes.object.isRequired,
};
export default withStyles(styles)(EditAccountDialog);
