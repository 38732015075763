
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Button, FormControlLabel, Typography, TextField, LinearProgress } from '@material-ui/core';
import LoggedInUserContext from '../loggedInUserContext';
import { Redirect } from 'react-router-dom';

const styles = theme => ({ 
    mainContainer: {
        width: '520px',
        margin: 'auto',
        justifyContent: 'center',
    },    
    bordered: {
        padding: '22px',
        marginTop: '24px',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    flexCol: {
        display: 'flex',
        flexDirection: 'column',
    },
    secondaryTitle: {
        color: theme.palette.primary[600],
        fontSize: '26px',
        marginTop: '22px',
        marginLeft: '24px',
        marginBottom: '12px'
    },
    list: {
        width: '240px'
    },
    varela: {
        fontFamily: 'Roboto'
    },
    flexGrow: {
        flex: '1 1 auto'
    },
    investorField: {
        margin: '32px'
    },
    fixedWidthMedium: {
        minWidth: '120px',
    },
    loginButton: {
        width: '120px',
        backgroundColor: theme.palette.primary[400],
        // float: 'right',
        margin: 'auto'
    },
    centeredText: {
        margin: 'auto'
    },
    justifyCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    forgotLink: {
        marginTop: '12px'
    },
    topSpace: {
        marginTop: '28px'
    },
    errorText: {
        fontSize: '14px',
        fontFamily: "font-family: 'Montserrat', sans-serif;"
    }
});

class LoginView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            redirectHome: false,
            email: undefined, //'demo@simpool.io',
            password: undefined, //'123456'
            errorText:undefined
        }
        // this.loginClick = this.loginClick.bind(this);
        this.doLogin = this.doLogin.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    async doLogin() {
        await this.setState({isLoading: true});
        const credentials = {
            email: this.state.email,
            password: this.state.password
        };
        const data = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            credentials: 'include',
            body: JSON.stringify(credentials)
        });
        const json = await data.json();
        if (data.status === 200 && json.user.is_system) {
            
            console.log("RESPONSE:", json);
            // UserStore.setAccount(json.account);
            // UserStore.setUser(json.user);
            sessionStorage.setItem('currentUser', JSON.stringify(json.user))
            sessionStorage.setItem('currentAccount', JSON.stringify(json.account))
            // await DataService.setTitles();
            await this.context.update(json.user.id);
            await this.setState({ isLoading: false, redirectHome: true});
        } else {
            console.log(`issues when trying to login: ${data.statusText}`);
            // TODO: differentiate between the different errors.
            // make sure the user/pwd mismatch is thrown by the server.
            await this.setState({ isLoading: false, errorText: 'The email address or password you entered are invalid, please try again' });
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }
    render() {
        const {classes} = this.props;
        const {isLoading, redirectHome} = this.state;
        console.log('redirect=', redirectHome);
        if (redirectHome) {
            return <Redirect to='/' />
        }
        return <div>
            <div className={classNames(classes.mainContainer, classes.bordered, classes.flexCol)}>
                <Typography variant="h6" className={classNames(classes.varela, classes.centeredText, classes.secondaryTitle)} primary>Admin Login</Typography>
                <div className= {classNames(classes.flexRow, classes.flexGrow)}>
                    <FormControlLabel className={classNames(classes.flexGrow)}
                        classes={{label: classes.fixedWidthMedium}} control={
                            <TextField className={classNames(classes.textField, classes.margin, classes.flexGrow, classes.investorField)} 
                            name="email" onChange={this.onChange}
                            />
                        }
                        label="Email" labelPlacement="start" />
                </div>
                <div className= {classNames(classes.flexRow, classes.flexGrow)}>                    
                    <FormControlLabel className={classNames(classes.flexGrow)} 
                        classes={{label: classes.fixedWidthMedium}} control={
                            <TextField type="password" onChange={this.onChange} name="password"
                            className={classNames(classes.textField, classes.margin, classes.flexGrow, classes.investorField)} />
                        }
                        label="Password" labelPlacement="start" />
                </div>
                
                <div className={classNames(classes.flexGrow, classes.justifyCenter)}>
                    <Button color="primary" variant="contained"  disabled={isLoading}
                        onClick={this.doLogin}>Sign in to Simpool
                    </Button>
                    <br />
                    <br />
                </div>
                {isLoading && <LinearProgress color="secondary" variant="query" />}
                <Typography className={classNames(classes.errorText)} variant='caption' color="error">{this.state.errorText}</Typography>
            </div>
        </div>
    }
}

LoginView.propTypes = {
    classes: PropTypes.object.isRequired,
};
LoginView.contextType = LoggedInUserContext;

export default withStyles(styles)(LoginView);