import React from 'react';
import { Paper, LinearProgress} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import EditConfigurationDialog from './EditConfigurationDialog';
import CreateIcon from "@material-ui/icons/Create";

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    createIcon: {
        cursor: 'pointer'
    }
});

class ConfigurationDataTable extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            configuration: undefined,
            clickedIndex: undefined,
            editOpen: false
        }
        this.getConfiguration = this.getConfiguration.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.onEditConfigurationClose = this.onEditConfigurationClose.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
    }
    headRows = [
        { id: 'id', numeric: false,  label: 'Id' },
        { id: 'type', numeric: false,  label: 'Type' },
        { id: 'updated', numeric: false,  label: 'Updated' },
        { id: 'data', numeric: false, label: 'Data' },
        { id: 'name', numeric: false, label: 'Name' },
        { id: 'account_id', numeric: false, label: 'Account Id' },
        { id: 'title_id', numeric: false, label: 'Title Id' },
        { id: 'actions', label: 'Actions', options: {
            filter: false, 
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <CreateIcon className={this.props.classes.createIcon} onClick={(e) => {
                        this.onEditClick(tableMeta.rowIndex);
                        e.stopPropagation();
                        return true;                
                    }} />
                );
            },
        }}
    ];
    async onEditClick(idx) {
        await this.setState({editOpen: true, clickedIndex: idx });
    }

    async onEditClick(idx) {
        await this.setState({editOpen: true, clickedIndex: idx });
    }

    onEditConfigurationClose() {
        this.setState({editOpen: false, clickedIndex: undefined});
    }
    async getConfiguration() {
        await this.setState({ isLoading: true });
        const data = await fetch(`${process.env.REACT_APP_API_URL}/api/growth/configuration`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: "GET",
            credentials: 'include'
        });
        const json = await data.json();
        // console.log(json);
        if (data.status === 201 || data.status === 200) {
            console.log(json);
            const rows = json.filter (row => { return ['108', '109'].indexOf(row.id) >= 0 ? row: undefined;});
            console.log(rows);
            await this.setState({configuration: rows});
        } else {
            console.log(`issues when trying to fetch Configuration: ${data.statusText}`);
        }
        await this.setState({ isLoading: false });
    }

    async componentDidMount() {
        await this.getConfiguration();
    }

    
    render() {
        const {classes} = this.props;
        const { isLoading, configuration, clickedIndex, editOpen } = this.state;        
        return <div>
            <Paper className={classes.root}>
                <MUIDataTable 
                    title={"Configuration"}
                    data={configuration && configuration.map(t => {
                        const parsed = undefined;
                        if (t.data) {
                            console.log('PARSED', t.data);
                        }
                        return [t.id, t.type, t.updated, JSON.stringify(t.data), t.name, t.account_id, t.title_id];
                    })  || undefined}
                    columns={this.headRows.map(h => {
                        return {
                            name: h.id,
                            label: h.label,
                            options: h.options
                        };
                    })}
                    options={{
                        responsive: 'scroll',
                        filterType: 'dropdown', pagination: false, sort: true, viewColumns: false
                    }}
                />
                { isLoading && <LinearProgress /> }
                {clickedIndex !== undefined && <EditConfigurationDialog configuration={configuration[clickedIndex]} open={editOpen} onClose={this.onEditConfigurationClose} />}
            </Paper>
      </div>
    }
}

ConfigurationDataTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConfigurationDataTable);