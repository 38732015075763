import React from 'react';
import { Paper, Typography, LinearProgress} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";

const styles = theme => ({
  root: {
    width: '100%',
    margin: '12px',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});

/*
account_id	
account_name	
account_creation_time	
title_id	
title_name	
title_creation_time	
user_id	
username	
email	
phone	
user_creation_time
*/

class CustomersDataTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            customers: undefined,
        }
        this.getCustomersSummary = this.getCustomersSummary.bind(this);
    }

    headRows = [
        { id: 'account_id', numeric: false,  label: 'Account Id' },
        { id: 'account_name', numeric: false,  label: 'Account Name' },
        { id: 'account_creation_time', numeric: false, label: 'Creation Time' },
        { id: 'title_id', numeric: false, label: 'Title Id' },
        { id: 'title_name', numeric: false, label: 'Title Name' },
        { id: 'title_creation_time', numeric: false, label: 'Title Creation' },
        { id: 'user_id', numeric: false, label: 'User Id' },
        { id: 'username', numeric: false, label: 'Username' },
        { id: 'email', numeric: false, label: 'Email' },
        { id: 'phone', numeric: false, label: 'Phone' },
        { id: 'user_creation_time', numeric: false, label: 'User Creation Time' }
    ];

    async getCustomersSummary() {
        await this.setState({ isLoading: true });
        
        const data = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/account/users/customersummary`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: "GET",
            credentials: 'include'
        });
        const json = await data.json();
        console.log("Customers,", json);
        if (data.status === 201 || data.status === 200) {
            await this.setState({customers: json});
        } else {
            console.log(`issues when trying to fetch customers summary: ${data.statusText}`);
        }
        await this.setState({ isLoading: false });
    }

    async componentDidMount() {
        await this.getCustomersSummary();
    }
    
    render() {
        const {classes} = this.props;
        const { isLoading, customers } = this.state;        

        return <div>
            <Paper className={classes.root}>
                <MUIDataTable
                    title={"Customers"}
                    data={customers && customers.map(t=>{
                        const a = this.headRows.map(hr => {
                            const colName = hr['id'];
                            return t[colName] ? t[colName].toString() : 'N/A';
                        });
                        return a;
                    })  || undefined}
                    columns={this.headRows.map(h=> {
                        return {
                            name: h.id,
                            label: h.label
                        };
                    })}
                    options={{filterType: 'dropdown', pagination: false, filter:true, sort: true, viewColumns: false}}
                />
                { isLoading && <LinearProgress /> }
            </Paper>
      </div>
    }
}

CustomersDataTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomersDataTable);