import { Route, Redirect, BrowserRouter } from 'react-router-dom';
import React from 'react';
import App from './App';
import theme from './Theme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import LoggedInUserContext from './loggedInUserContext';

import LoginView from './components/LoginView';
import MainView from './components/MainView';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <LoggedInUserContext.Consumer>
        {context => ( 
            <Route {...rest} render={props =>
                context.user_id ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to = {{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />)}
    </LoggedInUserContext.Consumer>
);

const SimpoolRouter = ({}) => (
    <BrowserRouter>
        <MuiThemeProvider theme={theme}>
            <App>
                <PrivateRoute exact path="/" component={MainView} />
                <Route path="/login" component={LoginView} />
            </App>
        </MuiThemeProvider>
    </BrowserRouter>
);

export default SimpoolRouter;