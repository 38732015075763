import React from 'react';
import PropTypes from 'prop-types';
import {DialogTitle, Dialog, TextField, DialogContent, LinearProgress, DialogActions, Button} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        minWidth: '360px',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    textField: {
        padding: '12px',
        margin: '8px'
    }
});


class EditUserDialog extends React.Component {    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            user: this.props.user,
        }
        this.onChange = this.onChange.bind(this);
        this.doUpdate = this.doUpdate.bind(this);
    }
    onChange(e) {
        const {user} = this.state;
        user[[e.target.name]] = e.target.value;
        this.setState(user);
    }

    async doUpdate() {
        const {user} = this.state;
        const method = user.id ? 'PUT' : 'POST';
        const url = user.id ? `https://stage.api.simpool.io/api/auth/account/user/${user.id}` : 
            `https://stage.api.simpool.io/api/auth/account/user`;
        await this.setState({ isLoading: true });
        console.log(`using ${JSON.stringify(user)} to create a user`);
        const data = await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method,
            credentials: 'include',
            body: JSON.stringify(user)
        });
        const json = await data.json();
        if (data.status === 201 || data.status === 200) {
            console.log('updated the user');
            this.props.onClose();
        } else {
            console.log(`issues when trying to udpate/create user: ${data.statusText}`);
        }
        await this.setState({ isLoading: false });        
    }
    
    render() {
        const {classes, open, onClose} = this.props;
        const {user, isLoading} = this.state;
        return (
            <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} className={classes.root}>
                <DialogTitle id="simple-dialog-title">Edit User</DialogTitle>
                <DialogContent dividers>
                    <TextField className={classes.textField} label='Name' name='name' onChange={this.onChange} value={user.name} />
                    <TextField className={classes.textField} label='Email' name='email' onChange={this.onChange} value={user.email} />
                    {isLoading && <LinearProgress />}                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary">
                        CANCEL
                    </Button>
                    <Button onClick={this.doUpdate} color="primary" autoFocus>
                        {user.id && 'UPDATE' || 'CREATE'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditUserDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
};
export default withStyles(styles)(EditUserDialog);
