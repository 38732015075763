import React from 'react';
import { Paper, Typography, LinearProgress, TableCell, TableRow} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import {orange} from '@material-ui/core/colors';
import CustomToolbar from './CustomTableToolBar';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import EditUserDialog  from './EditUserDialog';

import CreateIcon from "@material-ui/icons/Create";

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: '8px',
    overflowX: 'auto',
  },
  table: {
    minWidth: "100%",
  },
  fullRow: {
      width: '100%'
  },
  createIcon: {
      cursor: 'pointer'
  }
});

class UsersTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            users: undefined,
            editOpen: false,
            clickedIndex: undefined
        }
        this.getUsers = this.getUsers.bind(this);
        this.onRowsDelete = this.onRowsDelete.bind(this);
        this.doDelete = this.doDelete.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.onEditUserClose = this.onEditUserClose.bind(this);
        this.createNewUserRow = this.createNewUserRow.bind(this);
    }
    headRows = [
        { id: 'id', numeric: false,  label: 'Id' },
        { id: 'name', numeric: false,  label: 'Name' },
        { id: 'email', numeric: false,  label: 'Email' },
        { id: 'phone', numeric: false,  label: 'Phone' },
        { id: 'is_admin', numeric: false,  label: 'Admin' },
        { id: 'status', numeric: false, label: 'Status' },
        { id: 'created', numeric: false, label: 'Created At' },
        { id: 'updated', numeric: false, label: 'Updated At' },
        { id: 'actions', label: 'Actions', options: {
            filter: false, 
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <CreateIcon className={this.props.classes.createIcon} onClick={(e) => {
                        this.onEditClick(tableMeta.rowIndex);
                        e.stopPropagation();
                        return true;                
                    }} />
                );
            },
        }}
    ];
    getMuiTheme = () => createMuiTheme({
      overrides: {
        MuiToolbar: {
          root: {
            backgroundColor: orange[100],
        },
        //   actions: {
        //     backgroundColor: orange[400]
        //   }
        },
        MUIDataTableToolbar: {
          root: {
            backgroundColor: orange[100],
        },
        //   actions: {
        //     backgroundColor: orange[400]
        //   }
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: orange[100],
          }
        },
        MUIDataTable :{
            root: {
                backgroundColor: orange[100],
        }
        }
      }
    });
    
    async onEditClick(idx) {
        await this.setState({editOpen: true, clickedIndex: idx });
    }
    async getUsers() {
        if (!this.props.account_id || this.state.isLoading) return;
        await this.setState({ isLoading: true });
        try {
            const data = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/account/${this.props.account_id}/users`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                method: "GET",
                credentials: 'include'
            });
            const json = await data.json();
            console.log("JSON", json)
            if (data.status === 201 || data.status === 200) {
                await this.setState({users: json.users});
            } else {
                console.log(`issues when trying to fetch users: ${data.statusText}`);
            }
        } catch (err) {
            console.log(`issues when trying to fetch users: ${err}`);
        }
        await this.setState({ isLoading: false });
    }

    async componentDidMount() {
        await this.getUsers();
    }
    
    async doDelete(userId) {
        await this.setState({ isLoading: true });
        const data = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/account/user/${userId}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: "DELETE",
            credentials: 'include'
        });
        const json = await data.json();
        if (data.status === 201 || data.status === 200) {
            // await this.setState({accounts: json.accounts});
        } else {
            console.log(`issues when trying to fetch accounts: ${data.statusText}`);
        }
        await this.setState({ isLoading: false });
    }

    async onRowsDelete(rowsDeleted, data) {
        console.log(rowsDeleted); //object of index=>boolean (selected for delish)
        if (rowsDeleted.data.length > 1) {
            console.log('Deleting more than one account is not supported atm.');
            return false;
        } else {
            const userToDelete = this.state.accounts[rowsDeleted.data[0].dataIndex];
            await this.doDelete(userToDelete.id);
        }
    }
    async onEditUserClose() {
        this.setState({editOpen: false, clickedIndex: undefined});
    }

    async createNewUserRow() {
        const {users} = this.state;
        const newRow = { id :undefined, account_id: this.props.account_id, name: 'User Name', email: undefined, phone: 0, is_admin: false, status: undefined, created: undefined, updated: undefined };
        if (users)  {
            users.splice(0, 0, newRow); // or push(newRow) to put at the end
            await this.setState({users})
        } else await this.setState([newRow]);
    }
    
    render() {
        const {classes} = this.props;
        const { isLoading, users, editOpen, clickedIndex } = this.state;        
        return <TableRow className={classes.fullRow}><TableCell colSpan={this.headRows.length + 1}>
            {/* <MuiThemeProvider theme={this.getMuiTheme()}> */}
                <Paper className={classes.root}>
                    <MUIDataTable
                        title={"Users"}
                        data={users && users.map(t=>{
                            const a = this.headRows.map(hr => {
                                const colName = hr['id'];
                                return t[colName] ? t[colName].toString() : undefined;
                            });
                            return a;
                        })  || undefined}
                        columns={this.headRows.map(h=> {
                            return {
                                name: h.id,
                                label: h.label,
                                options: h.options
                            };
                        })}
                        options={{                        
                            filterType: 'dropdown', pagination: false, sort: true, viewColumns: false,
                            onRowsDelete: this.onRowsDelete,
                            customToolbar: () => {
                                return (
                                    <CustomToolbar onCreate={this.createNewUserRow} />
                                );
                            },
                        }}
                    />
                    { isLoading && <LinearProgress /> }
                    {clickedIndex !== undefined && <EditUserDialog user={users[clickedIndex]} open={editOpen} onClose={this.onEditUserClose} />}

                </Paper>
            {/* </MuiThemeProvider> */}
            </TableCell></TableRow>
    }
}

UsersTable.propTypes = {
    classes: PropTypes.object.isRequired,
    account_id: PropTypes.string.isRequired
};

export default withStyles(styles)(UsersTable);