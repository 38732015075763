import React from 'react';
import { Paper, TableRow, TableCell, LinearProgress} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import JobPerformanceDataTable from './JobPerformanceDataTable';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  line: {
      display: 'table-row',
      flexDirection: 'row'
  }
});

const headRows = [
    { id: 'title_id', numeric: false,  label: 'Title Id' },
    { id: 'bucket_name', numeric: false,  label: 'Bucket Name' },
    { id: 'last_extraction_status', numeric: false, label: 'Last Extraction Status' },
    { id: 'last_extraction_dt', numeric: false, label: 'Last Extraction Date' },
    { id: 'last_extraction_job_id', numeric: false, label: 'Last Extraction Job Id' },
    { id: 'last_modeljob_status', numeric: false, label: 'Last Modeljob Status' },
    { id: 'last_modeljob_dt', numeric: false, label: 'Last Modeljob Date' },
    { id: 'last_modeljob_job_id', numeric: false, label: 'Last Modeljob Job ID' },
    { id: 'last_athenaetljob_status', numeric: false, label: 'Last Athen ETLjob Status' },
    { id: 'last_athenaetljob_dt', numeric: false, label: 'Last Athen ETLjob Date' },
    { id: 'last_athenaetljob_job_id', numeric: false, label: 'Last Athen ETLjob Job Id' },
];

class JobsDataTable extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            jobs: undefined,
        }
        this.getJobs = this.getJobs.bind(this);
    }

    async getJobs() {
        await this.setState({ isLoading: true });
        
        const data = await fetch(`${process.env.REACT_APP_API_URL}/api/simulation/jobs/adminetljobs`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: "GET",
            credentials: 'include'
        });
        const json = await data.json();
        if (data.status === 201 || data.status === 200) {
            await this.setState({jobs: json});
        } else {
            console.log(`issues when trying to fetch jobs: ${data.statusText}`);
        }
        await this.setState({ isLoading: false });
    }

    async componentDidMount() {
        await this.getJobs();
        console.log(this.state.jobs);
    }
    
    render() {
        const {classes} = this.props;
        const { isLoading, jobs } = this.state;        

        return <div>
            <Paper className={classes.root}>
                <MUIDataTable
                    title={"Jobs"}
                    data={jobs && jobs.map(t => {
                        const ret = [];
                        headRows.forEach(c => { ret.push(t[c.id] || '---'); });
                        return ret;
                    })  || undefined}
                    columns={headRows.map(h=> {
                        return {
                            name: h.id,
                            label: h.label
                        };
                    })}
                    options={{
                        expandableRows: true, 
                        expandableRowsOnClick: true, 
                        renderExpandableRow: (rowData, rowMeta) => { 
                            return <TableRow className={classes.fullRow}>
                                <TableCell colSpan={headRows.length + 1}>
                                    <Paper className={classes.root}>
                                        <JobPerformanceDataTable name="Extraction Perf" job_id={rowData[4]} />
                                        <JobPerformanceDataTable name="Model perf" job_id={rowData[7]} />
                                        <JobPerformanceDataTable name="Athena perf" job_id={rowData[10]} />
                                    </Paper>
                                </TableCell>
                            </TableRow>
                        },
                        filterType: 'dropdown', pagination: false, sort: true, viewColumns: false}}
                />
                { isLoading && <LinearProgress /> }
            </Paper>
      </div>
    }
}

JobsDataTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(JobsDataTable);