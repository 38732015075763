import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooksOutlined';
import AccountBoxIcon from '@material-ui/icons/AccountBoxOutlined';
import SettingsApplicationsOutlined from '@material-ui/icons/SettingsApplicationsOutlined'

import TasksTable from './TasksTable';
import AccountsTable from './AccountsTable';
import ConusmersDataTable from './ConusmersDataTable';
import CustomersDataTable from './CustomersDataTable';
import JobsDataTable from './JobsDataTable';
import ConfigurationDataTable from './ConfigurationDataTable';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

export default function MainView() {
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const classes = useStyles();
   
    function handleListItemClick(event, index) {
        setSelectedIndex(index);
    }

    return (
        <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <Typography variant="h6" noWrap>
                    Simpool Admin Lounge
                </Typography>
            </Toolbar>
        </AppBar>
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
            paper: classes.drawerPaper,
            }}
            anchor="left"
        >
            <div className={classes.toolbar} />
            <Divider />
            <List>
                <ListItem button key={"tasks"} selected={selectedIndex===0}
                    onClick={event => handleListItemClick(event, 0)}>
                    <ListItemIcon><LibraryBooksIcon /></ListItemIcon>
                    <ListItemText primary={"Tasks"} />
                </ListItem>
                <ListItem button key={"jobs"} selected={selectedIndex===1}
                    onClick={event => handleListItemClick(event, 1)}>
                    <ListItemIcon><LibraryBooksIcon /></ListItemIcon>
                    <ListItemText primary={"Jobs Status"} />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button key={"accounts"} selected={selectedIndex===2}
                    onClick={event => handleListItemClick(event, 2)}>
                    <ListItemIcon><AccountBoxIcon /></ListItemIcon>
                    <ListItemText primary={"Accounts"} />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button key={"consumers"} selected={selectedIndex===3}
                    onClick={event => handleListItemClick(event, 3)}>
                    <ListItemIcon><AccountBoxIcon /></ListItemIcon>
                    <ListItemText primary={"Consumers"} />
                </ListItem>
                <ListItem button key={"customerssummary"} selected={selectedIndex===4}
                    onClick={event => handleListItemClick(event, 4)}>
                    <ListItemIcon><AccountBoxIcon /></ListItemIcon>
                    <ListItemText primary={"Customers Summary"} />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button key={"configuration"} selected={selectedIndex===5}
                    onClick={event => handleListItemClick(event, 5)}>
                    <ListItemIcon><SettingsApplicationsOutlined color="secondary" /></ListItemIcon>
                    <ListItemText primary={"Configuration"} />
                </ListItem>
            </List>

        </Drawer>
        <main className={classes.content}>
            <div className={classes.toolbar} />
            {selectedIndex === 0 && <TasksTable />}
            {selectedIndex === 1 && <JobsDataTable />}
            {selectedIndex === 2 && <AccountsTable />}
            {selectedIndex === 3 && <ConusmersDataTable />}
            {selectedIndex === 4 && <CustomersDataTable />}
            {selectedIndex === 5 && <ConfigurationDataTable />}
        </main>
        </div>
    );
}