import React from 'react';
import { Paper, Typography, LinearProgress} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});

const headRows = [
    { id: 'title_id', numeric: false,  label: 'Title Id' },
    { id: 'task_name', numeric: false,  label: 'Task Name' },
    { id: 'run_time', numeric: false, label: 'Last Successful Runtime' },
    { id: 'status', numeric: false, label: 'Status' },
    { id: 'last_failed_step', numeric: false, label: 'Last Failed Step' },
    { id: 'job_id', numeric: false, label: 'Job Id' },
];

class TasksTable extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            tasks: undefined,
        }
        this.getTasks = this.getTasks.bind(this);
    }

    async getTasks() {
        await this.setState({ isLoading: true });
        
        const data = await fetch(`${process.env.REACT_APP_API_URL}/api/simulation/jobs/tasks`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: "GET",
            credentials: 'include'
        });
        const json = await data.json();
        if (data.status === 201 || data.status === 200) {
            await this.setState({tasks: json});
        } else {
            console.log(`issues when trying to fetch tasks: ${data.statusText}`);
        }
        await this.setState({ isLoading: false });
    }

    async componentDidMount() {
        await this.getTasks();
    }
    
    render() {
        const {classes} = this.props;
        const { isLoading, tasks } = this.state;        

        return <div>
            <Paper className={classes.root}>
                <MUIDataTable
                    title={"Tasks"}
                    data={tasks && tasks.map(t=>{
                        return [t.title_id, t.task_name, t.run_time, t.status, t.last_failed_step, t.job_id];
                    })  || undefined}
                    columns={headRows.map(h=> {
                        return {
                            name: h.id,
                            label: h.label
                        };
                    })}
                    options={{filterType: 'dropdown', pagination: false, sort: true, viewColumns: false}}
                />
                { isLoading && <LinearProgress /> }
            </Paper>
      </div>
    }
}

TasksTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TasksTable);