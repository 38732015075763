import React from 'react';
import PropTypes from 'prop-types';
import {DialogTitle, Dialog, DialogContent, LinearProgress, DialogActions, Button} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import JSONInput from 'react-json-editor-ajrm';
import locale    from 'react-json-editor-ajrm/locale/en';

const styles = theme => ({
    root: {
        minWidth: '360px',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    }
});


class EditConfigurationDialog extends React.Component {    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            configuration: this.props.configuration,
        }
        this.onChange = this.onChange.bind(this);
        this.doUpdate = this.doUpdate.bind(this);
    }
    async onChange(obj) {
        const {configuration} = this.state;
        configuration['data'] = JSON.parse(obj.json);
        await this.setState(configuration);
    }

    async doUpdate() {
        const {configuration} = this.state;
        const url = `${process.env.REACT_APP_API_URL}/api/growth/${configuration.account_id}/${configuration.title_id}/configuration/${configuration.name}`;
        await this.setState({ isLoading: true });
        const data = await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(configuration.data)
        });
        const json = await data.json();
        if (data.status === 201 || data.status === 200) {
            console.log('updated configuration', JSON.stringify(json));
            this.props.onClose();
        } else {
            console.log(`issues when trying update configuration: ${data.statusText}`);
        }
        await this.setState({ isLoading: false });        
    }
    
    render() {
        const {classes, open, onClose} = this.props;
        const {configuration, isLoading} = this.state;
        return (
            <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} className={classes.root}>
                <DialogTitle id="simple-dialog-title">Edit Configuration</DialogTitle>
                <DialogContent dividers>
                    <JSONInput 
                        locale={locale} 
                        placeholder= {configuration.data}
                        onChange={this.onChange} 
                        theme={'light'}
                    />
                    {/* <TextField label='Data' name='data' onChange={this.onChange} value={configuration.data} /> */}
                    {isLoading && <LinearProgress />}                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary">
                        CANCEL
                    </Button>
                    <Button onClick={this.doUpdate} color="primary" autoFocus>
                        UPDATE
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditConfigurationDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    configuration: PropTypes.object.isRequired,
};
export default withStyles(styles)(EditConfigurationDialog);
