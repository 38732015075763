import React from 'react';
import LoggedInUserContext from  './loggedInUserContext';


// const serverURL = (process.env.NODE_ENV === 'production') ? 'simpool-demo.herokuapp.com' : 'localhost:3000';
class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user_id: sessionStorage.getItem('currentUser') && JSON.parse(sessionStorage.getItem('currentUser')).id || undefined,
            update: async(userId) => {
                // if (!userId) {
                //     return this.setState(state => Object.assign({}, state,  { user: undefined }));
                // }
                // const res = await fetch(`http://${serverURL}/investors/${userId}`, {
                //     headers: {
                //         'Accept': 'application/json',
                //         'Content-Type': 'application/json'
                //     },
                //     method: "GET",
                // });
                // const user = await res.json();
                await this.setState(state => Object.assign({}, state, { user_id: userId }));
            }
        }
    }
    async componentDidMount() {
        try {
            let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
            let currentAccount = JSON.parse(sessionStorage.getItem('currentAccount'));
            if (currentUser && currentAccount) {
                await this.state.update(currentUser.id);
                // UserStore.setAccount(currentAccount);
                // UserStore.setUser(currentUser);
                // DataService.setTitles();
            }
        } catch (err) {
            console.error(err);
        }
    }

    render() {
        return <LoggedInUserContext.Provider value={this.state}>
            {this.props.children}
        </LoggedInUserContext.Provider>
    }
}

export default App;
