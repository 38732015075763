import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';

const defaultToolbarStyles = {
    iconButton: {
    },
};

class CustomToolbar extends React.Component {
    constructor(props) {
      super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick = () => {
        this.props.onCreate();
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Tooltip title={"custom icon"}>
                    <IconButton className={classes.iconButton} onClick={this.handleClick}>
                        <AddIcon className={classes.deleteIcon} />
                    </IconButton>
                </Tooltip>
            </React.Fragment>
        );
    }
}
CustomToolbar.propTypes = {
    onCreate: PropTypes.func.isRequired
};

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);