import React from 'react';
import { Paper, LinearProgress, TableCell, TableRow} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import {orange} from '@material-ui/core/colors';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const styles = theme => ({
    root: {
      width: '100%',
      marginTop: '8px',
      overflowX: 'auto',
    },
    table: {
      minWidth: "100%",
    },
    fullRow: {
        width: '100%'
    },
    createIcon: {
        cursor: 'pointer'
    }
  });

class JobPerformanceTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            jobPerformance: undefined,
            editOpen: false,
            clickedIndex: undefined
        }
        this.getJobPerformance = this.getJobPerformance.bind(this);
    }
    headRows = [
        { id: 'id', numeric: false,  label: 'Id' },
        { id: 'dtype', numeric: false,  label: 'Data' },
        { id: 'status', numeric: false,  label: 'Status' },
        { id: 'created_at', numeric: false,  label: 'Created At' },
        { id: 'job_id', numeric: false,  label: 'Job ID' }
    ];
    getMuiTheme = () => createMuiTheme({
      overrides: {
        MuiToolbar: {
          root: {
            backgroundColor: orange[100],
        },
        //   actions: {
        //     backgroundColor: orange[400]
        //   }
        },
        MUIDataTableToolbar: {
          root: {
            backgroundColor: orange[100],
        },
        //   actions: {
        //     backgroundColor: orange[400]
        //   }
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: orange[100],
          }
        },
        MUIDataTable :{
            root: {
                backgroundColor: orange[100],
        }
        }
      }
    });
    
    async getJobPerformance() {
        const {job_id} = this.props;
        if (!job_id || this.state.isLoading) return;
        await this.setState({ isLoading: true });
        try {
            const data = await fetch(`${process.env.REACT_APP_API_URL}/api/simulation/jobs/adminetljobperformance/${job_id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                method: "GET",
                credentials: 'include'
            });
            const json = await data.json();
            console.log("JSON", json)
            if (data.status === 201 || data.status === 200) {
                await this.setState({jobPerformance: json});
            } else {
                console.log(`issues when trying to fetch job performance: ${data.statusText}`);
            }
        } catch (err) {
            console.log(`issues when trying to fetch job performance: ${err}`);
        }
        await this.setState({ isLoading: false });
    }

    async componentDidMount() {
        await this.getJobPerformance();
    }
    
    render() {
        const {classes} = this.props;
        const { isLoading, jobPerformance } = this.state;        
        return <Paper className={classes.root}>
                    <MUIDataTable
                        title={this.props.name || "Performance"}
                        data={jobPerformance}
                        columns={this.headRows.map(h=> {
                            return {
                                name: h.id,
                                label: h.label,
                                options: h.options
                            };
                        })}
                        options={{                        
                            filterType: 'dropdown', pagination: false, sort: true, viewColumns: false,
                        }}
                    />
                    { isLoading && <LinearProgress /> }
                </Paper>
    }
}

JobPerformanceTable.propTypes = {
    classes: PropTypes.object.isRequired,
    job_id: PropTypes.string.isRequired,
    name: PropTypes.string
};

export default withStyles(styles)(JobPerformanceTable);